<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Create New Highlights
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span><strong>For SG-Nirvana</strong> This is the control panel where administrators can edit the content for the customer-facing mobile app.</span>
      </div>
    </b-alert>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitForm">
      <validation-observer
        ref="highlightsCreateForm"
        #default=""
      >
        <b-card
          header-tag="header"
        >
          <b-row>
            <b-col
              md="6"
              class="my-2"
            >
              <b-form-group
                label="Image*"
                label-cols-md="4"
                label-for="image"
              >
                <div class="d-flex justify-content-start">
                  <div
                    class="image__block"
                  >
                    <b-img
                      class="mb-1 mb-sm-0 user__avatar img-preview-block"
                      :src="fileSrc ? fileSrc : defaultImage"
                    />
                  </div>

                  <div
                    class="upload__buttons my-auto mx-2"
                  >
                    <b-form-file
                      ref="fileInput"
                      v-model="image"
                      type="file"
                      accept="image/jpeg, image/jpg, image/png"
                      style="display: none"
                      @input="setImage"
                    />
                    <b-button
                      variant="primary"
                      class="mr-50"
                      @click="$refs.fileInput.$el.childNodes[0].click()"
                    >
                      Upload
                    </b-button>
                    <b-button
                      v-if="fileSrc"
                      variant="outline-primary"
                      class="mr-50"
                      @click="removeFileSrc()"
                    >
                      Remove
                    </b-button>
                  </div>
                </div>
                <small class="text-danger">{{ fileError }}</small>
              </b-form-group>
            </b-col>
            <b-col md="6" />
            <b-col md="12">
              <b-form-group
                label="Title*"
                label-cols-md="2"
                label-for="title"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  vid="title"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="title"
                    :state="(errors.length > 0) ? false : null"
                    name="title"
                    placeholder="Maximum  50 characters"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="titleValidation"
                    class="text-danger"
                  >
                    {{ titleError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Description"
                label-for="event-description"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  vid="description"
                  rules=""
                >
                  <b-form-textarea
                    id="event-description"
                    v-model="description"
                    placeholder="Maximum  160 characters"
                    rows="3"
                    name="description"
                    @input="descriptionValidation == true ? descriptionValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="descriptionValidation"
                    class="text-danger"
                  >
                    {{ descriptionError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Date & Time*"
                label-cols-md="2"
                label-for="date"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Date & Time"
                  vid="date"
                  rules="required"
                >
                  <b-form-input
                    id="date"
                    v-model="date"
                    :state="(errors.length > 0) ? false : null"
                    name="date"
                    placeholder="E.g. Monday – Friday 8am to 4pm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="dateValidation"
                    class="text-danger"
                  >
                    {{ dateError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Location"
                label-cols-md="2"
                label-for="location"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Location"
                  vid="location"
                  rules=""
                >
                  <b-form-input
                    id="location"
                    v-model="location"
                    :state="(errors.length > 0) ? false : null"
                    name="location"
                    placeholder="Add address"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="locationValidation"
                    class="text-danger"
                  >
                    {{ locationError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="URL"
                label-cols-md="2"
                label-for="url"
              >
                <validation-provider
                  #default="{ errors }"
                  name="URL"
                  vid="url"
                  rules=""
                >
                  <b-form-input
                    id="url"
                    v-model="url"
                    :state="(errors.length > 0) ? false : null"
                    name="url"
                    placeholder="https://"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="urlValidation"
                    class="text-danger"
                  >
                    {{ urlError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-app-client-status"
                  label-cols-md="4"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-app-client-status"
                    v-model="status"
                    label="title"
                    :options="statusOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6" />
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'app-highlights-index' }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              @click="submitForm('draft')"
            >
              Save as Draft
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="success"
              :disabled="invalid || fileSrc == null || fileSrc == ''"
              @click="submitForm('submit')"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Create New Highlights</span>
            </b-button> -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="success"
              @click="submitForm('submit')"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Create New Highlights</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
    <!-- ./ create new category modal -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton, BNav, BImg, BFormFile, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BNav,
    BImg,
    BFormFile,
    BAlert,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      image: null,
      fileSrc: '',
      title: '',
      description: '',
      date: '',
      location: '',
      url: '',
      status: 'published',
      titleError: 'Valid title is required',
      titleValidation: false,
      descriptionError: 'Valid description is required',
      descriptionValidation: false,
      dateError: 'Valid date is required',
      dateValidation: false,
      locationError: 'Valid location is required',
      locationValidation: false,
      urlError: 'Valid url is required',
      urlValidation: false,
      statusError: 'Valid status is required',
      statusValidation: false,
      imageError: 'Valid image is required',
      imageValidation: false,
      fileError: '',
      statusOptions: [{ title: 'Published', code: 'published' }, { title: 'Unpublished', code: 'unpublished' }, { title: 'Draft', code: 'draft' }],
      // validation rules
      required,
    }
  },
  methods: {
    setImage() {
      if (this.image) {
        if (this.image && this.image.size <= 5242880) {
          const reader = new FileReader()
          reader.readAsDataURL(this.image)
          reader.onload = () => {
            this.fileSrc = reader.result
            this.fileError = ''
          }
        } else {
          this.fileSrc = null
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File size not accepted more than 5MB',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    removeFileSrc() {
      this.fileSrc = null
      this.image = null
    },
    submitForm(state) {
      this.$refs.highlightsCreateForm.validate().then(success => {
        if (!this.fileSrc) {
          this.fileError = 'The Image field is required'
        }
        if (success && this.fileSrc) {
          const formData = new FormData()

          if (state === 'draft') {
            this.status = 'draft'
          }

          formData.append('title', this.title)
          formData.append('description', this.description)
          formData.append('date', this.date)
          formData.append('location', this.location)
          formData.append('url', this.url)
          formData.append('status', this.status)
          formData.append('image', this.fileSrc)
          this.$http.post('directory/highlights/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.$swal({
                title: 'Highlight Created',
                html: '',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/success.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
                .then(result => {
                  if (result.value) {
                    this.$router.push({ name: 'app-highlights-index' })
                  }
                })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'title') {
                    this.titleError = validationError.msg
                    this.titleValidation = true
                  } else if (validationError.param === 'description') {
                    this.descriptionError = validationError.msg
                    this.descriptionValidation = true
                  } else if (validationError.param === 'date') {
                    this.dateError = validationError.msg
                    this.dateValidation = true
                  } else if (validationError.param === 'location') {
                    this.locationError = validationError.msg
                    this.locationValidation = true
                  } else if (validationError.param === 'url') {
                    this.urlError = validationError.msg
                    this.urlValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  } else if (validationError.param === 'image') {
                    this.imageError = validationError.msg
                    this.imageValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .step-class {
    color: #104D9D;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
  .img-preview-block{
      box-sizing: border-box;
      width: 100px;
      height: 100px;
      border: 1px solid #D2DCEA;
      border-radius: 5px;
  }
</style>
